// Grid
$hwidth: 1400px;
$width: 1200px;

// Colors
$primary: #091f40;      // Dark Blue
$secondary: #e9bd51;    // Gold
$accent: #7ea0c4;       // Light Blue
$black: $primary;
$placeholder: $black;
$white: #ffffff;
$grey1: #f2f2f2;
$grey2: #e6e6e6;
$grey3: #ccc;
$grey4: #fafafa;
$red: #ff383c;
$redmedium: #d44d4d;

// Fonts
$font1: 'Montserrat', sans-serif;
$fs: 16;
$icon: "kornferry";

// Media Queries
$phone: 440px;
$ipad: 756px;
$desktop: 980px;
$large: 1400px;