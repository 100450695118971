.sections {
	border-top:1px solid rgba( $primary, 0.1 );
	padding-top:20px;
	margin-top:20px;
	padding-bottom:20px;

	.section {

		~ .section {
			border-top:1px solid rgba( $primary, 0.1 );
			padding-top:20px;
			margin-top:20px;

			.field.review {
				border-top:1px solid rgba( $primary, 0.1 );
			}

		}
	}

	&.no-divider {
		.section ~ .section {
			border-top:0 none;
			padding-top:0;
			margin-top:0;
		}
	}
}

.field {
	label {
		display:block;
		margin-bottom:5px;
	}
	input[type="text"],
	input[type="number"] {
		width:100%;
		background:#fff;
		border:0 none;
		padding:10px 10px;
		font-size:1.2em;
	}
	.react-datepicker-wrapper {
		width: 100%;
	}
	.react-datepicker__input-container {
		width: 100%;
	}
	.validation-text {
		margin-top: 5px;
	}
	
	.field-wrap {
		&.invalid {

			label {
				color: $red;
				font-weight:700;
			}

			input {
				border:1px solid $red;
			}

			.validation-text {
				color: $red;
			}
		}
	}

	&.monetary,
	&.percentage {
		.input-wrap {
			&::before {
				content: '$';
				position:absolute;
				top:0;
				left:0;
				color:$grey3;
				font-size:1.2em;
				line-height: 1.2em;
				padding:10px 0px;
				width:1.4em;
				text-align: right;
			}
		}
		input {
			padding-left:calc(1.4em + 5px);
		}
	}
	&.percentage {
		.input-wrap::before {
			content: '%';
		}
	}
	&.years-of-service-chart {
		margin-top:20px;

		.field-wrap > label {
			font-size:1.2em;
			font-weight:700;
			margin-bottom: 10px;
		}

		table {
			width: 100%;
			background:$grey4;
			border:2px solid $grey4;
			border-collapse: collapse;

			th,td {
				border: 1px solid rgba( $primary, 0.1 ); 
				line-height:1em;
				vertical-align: middle;
			}

			th {
				font-weight:400;
				padding:15px 12px;
				text-align:left;
				min-width:100px;
			}
			td {
				background:#fff;
			}

			tr.total {
				td,input {
					background:$grey4;
				}
			}
		}

		.field-wrap.invalid {
			input {
				border:0 none;
			}
			tr.total {
				input {
					border: 1px solid $red;
				}
			}
		}
	}

	&.review {
		font-size:1.2em;

		.field-wrap {
			display: flex;
			align-items: center;

			> * {
				width:50%;
				padding:10px 15px;
			}

			label {
				background:$grey2;
				margin-bottom:0;
			}

			span {
				white-space: nowrap;
			}
		}

		+ .field {
			margin:0;
			border-top:1px solid rgba( $primary, 0.1 );
		}
	}

	&.memberResultTabs {
		.tabs {
			// display: flex;
			justify-content: space-between;
			align-items: center;

			> a {
				display: block;
				text-align:center;
				padding:20px 0;
				font-size:1.2em;
				// width:calc(20% - 12px);
				width:100%;
				border:2px solid $grey2;
				color:$primary;
				transition: all 300ms;
				text-decoration: none;
				margin-bottom:4px;
				
				&:focus,
				&:hover {
					color:$primary;
					border-color:$primary;
				}

				&.current {
					background: $primary;
					border-color: $primary;
					color:#fff;
				}
			}

			.or {
				// width:30px;
				width:100%;
				text-align:center;
				display:block;
				margin-bottom:4px;
			}
		}
	}

	&.memberResultsTable {

		.table-wrap {
			// overflow-x: scroll;
		}

		.firstYear {
			>th {
				font-weight: bold;
				color: red !important;
			}
		}
		
	
		.caption {
			margin-top:30px;
			font-size:1.2em;

			h6 {
				margin-bottom:5px;

				em {
					display:block;
					font-size:0.7em;
					margin-top:5px;
				}
			}
		}

		.swipe {
			font-size:0.8em;
			margin-bottom:10px;
		}

		table {
			width:100%;
			border:2px solid $grey1;

			

			caption {
				text-align: left;
				font-size:1.2em;
				font-weight: 700;
			}

			th, td {
				padding:10px 10px;
				border:1px solid rgba( $primary, 0.1 );
				vertical-align: middle;
			}

			td {
				text-align: center;
				font-size:1.3em;
			}

			th {
				position: relative;
				background:$grey2;
				&.bold.bold.bold {
					font-weight: bold;
				}
			}

			th:first-child {
				width: 200px;
				text-align: left;
				font-weight: 400;
				line-height:1.2em;
			}



			tr {
				&.header {
					th {
						font-weight:700;

						em {
							font-size:0.7em;
							display: block;
							margin-top:4px;
						}
					}
				}
				&.inset1,
				&.inset2 {
					th, td {
						border-top:0 none;
						border-bottom:0 none;
					}
					th {
						padding-left:10px;
					}
				}
				&.inset2 {
					th {
						padding-left:30px;
					}

					+ .inset1 {
						border-top:1px solid rgba( $primary, 0.1 );
					}
				}
			}
		}
	}

	.input-wrap {
		position:relative;
	}

	+ .field {
		margin-top:10px;
	}
}

.buttons {
	.prev {
		float:left;
		margin-right:5px;
	}
	.next {
		float:right;
		margin-left:5px;
	}
	button {
		margin-bottom:10px;
	}
}


label {
	position: relative;
	.calender-ico {
		position: absolute;
		width: 50px;
		height:100%;
		background-color: $secondary;
		top: 0;
		right: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: 350ms all;
		cursor: pointer;
		svg {
			width: 20px;
			height: 20px;
			path {
				fill: white;
				transition: 350ms all;
			}
		}
	}
	&:hover {
		.calender-ico {
			background-color: $primary;
		}

	}
}