html {
    height:100%;
    background:$primary;
}

body{
    margin:0;
    padding:0;
    color:$black;
    font:normal 14px/1.5 $font1;
    font-weight:300;
    height:100%;
}

em.small {
    font-weight: normal;
}

.footnotes {
    li {
        margin-bottom: 10px;
    }
}

span.small {
    font-size: 12px;
}

p.regular {
    font-weight: normal;
}

p.nm.nm.nm {
    margin-bottom: 0em;
}

section.intro {
    color: white;
    text-align: left;
}

div.tooltip {
    background:$secondary;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius:50%;
    display:inline-block;
    vertical-align: top;
    font-size:0.7em;
    width:1.2em;
    height:1.2em;
    text-align: center;
    line-height:1.2em;
    color: #fff;
    transition: all 300ms;
    margin-left:5px;
    text-decoration: none;

    span {
        font-family: serif;
        font-style: italic;
    }
    
    &:focus,
    &:hover {
        background:darken($secondary,15%);;
    }
}

.tooltip-box.tooltip-box.tooltip-box {
    max-width: 500px;
    background-color: $primary;
    opacity: 1;
}

#root {
    height: 100%;
    overflow-x: hidden;

    > div {
        height: 100%;
    }

    > div > div  {
        min-height: 100%;
        background-color:$grey1;
        padding-bottom:60px;
        position:relative;
    }
}

main {
    padding:24px 0;
}

.font-size-small {
    font-size: 14px;
}
.font-size-medium {
    font-size: 16px;
}
.font-size-large {
    font-size: 18px;
}

* {
    box-sizing:border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
    color:$primary;
}

h2, h3, h4, h5, h6 {
    font-weight: bold;
    margin-bottom:10px;
    line-height: 1.25;
}

h2 {
    font-size:em(30,$fs);
}

h3 {
    font-size:em(20,$fs);
}

p {
    margin: 0 0 1em;
}

strong {
    font-weight: 700;
}

main, footer, header .links {
    a:not(.logo):not(.btn):not(.tooltip):not(.tab){
        text-decoration: none;
        color:$secondary;
        transition:all 300ms ease-in-out;
        
        &:focus,
        &:hover{
            color:darken($secondary,15%);
        }
    }
}

iframe {
  max-width: 100%;
}

%container {
    max-width: $width;
    width:100%;
    margin:0 auto;
    position:relative;
    padding:0 20px;

    &.wide {
        max-width:1400px;
        padding:0;
    }
}

.container {
    @extend %container;
}

%flicker {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
}

%icon {
    font-family:'fontello';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.disclaimer {
    // color: $red;
    // border: 1px solid rgba($red,0.5);
    // padding: 10px 15px;
    margin-bottom: 12px;
    
    p {
        margin: 0;
    }
}

.wrap{
    width:100%;
    padding:0 em(15,$fs);
    position: relative;

    > *{
        @include afterclear;
        //max-width:$width;
        width:100%;
        margin:0 auto;
        position: relative;
    }

    > header{
        //max-width:$hwidth;
        width:100%;
    }
}

#main_wrap{
    //overflow:hidden; //cuts off search choices
    padding-bottom:em(30,$fs);
}

.loader,
.error-message {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    color: white;
}

.secondary{
    color:$secondary;
}

.btn{
    background:transparent;
    display:inline-block;
    color:$white;
    // border:3px $secondary solid;
    outline:none;
    line-height:1;
    padding:0;
    min-width:100px;
    // text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    font-size:1.2em;
    text-align: center;
    position: relative;
    border: 0 none;

    > span {        
        display:inline-block;
        padding:15px 18px;
        background:$primary;
        line-height: 1em;
        transition:all 300ms;
        width:100%;
    }

    &:before {
        background:$primary;
    }

    &.short > span {
        padding:8px 18px;
    }

    .loader{
        @include loader02($align:middle,$color:$white);
        margin:-20px auto 0;
        width:40px;
        height:40px;
        position: absolute;
        top:50%;
        right:5px;
        display:none;
        transform:translateY(-50%);
    }
    
    &:focus > span,
    &:hover > span {
        background:lighten($primary,15%);
    }

    &.secondary{
        > span,
        &:before {
            background:$secondary;
        }

        &:focus > span,
        &:hover > span {
            background:darken($secondary,15%);
        }

        &.reverse{
            border:3px $secondary solid;
            background:$white;
            color:$secondary;
            
            &:focus,
            &:hover{
                background:$secondary;
                color:$white;
            }
        }

        &.pulse{
            animation:1.5s secondary-pulse infinite;
        }
    }

    &[class*="icon-"]{
        padding-right:calc(1em + 31px);

        &:before{
            position: absolute;
            top:0;
            right:0;
            font-size:1em;
            margin:0;
            display:block;
            width: calc(1em + 30px);
            line-height: calc(1em + 30px);
            transition: all 300ms;
        }

        &.icon-reverse{
            padding-right:em(60,$fs);
            padding-left:em(100,$fs);

            &:before{
                right:auto;
                left:15px;
            }
        }

        &[data-loading=true]{
            &:before{
                display:none !important;
            }
            .loader{
                display:block;
            }
        }

        &[data-failed=true]{
            border-color:$red;
            background:$red;

            &:before{
                content:"\e807";
            }
        }
    }

    &.small{
        font-size:em(27.17,$fs);
        padding:em(15,$fs);

        &[class*="icon-"]{
            padding-right:em(60,$fs);

            &:before{
                font-size:1.2em;
            }

            &.icon-reverse{
                padding-right:em(15,$fs);
                padding-left:em(60,$fs);
            }
        }
    }

    &.right{
        text-align: right;
    }

    &.reverse{
        border-color:$primary;
        background:$white;
        color:$primary;
        
        &:focus,
        &:hover{
            background:$primary;
            color:$white;
        }
    }

    &.inline{
        width:auto;
    }

    &.x-small{
        font-size:em(22,$fs);
        padding:em(5,$fs) em(15,$fs);
    }

    &.xx-small{
        font-size:em(16,$fs);
        padding:em(5,$fs) em(15,$fs);
    }

    &[rel=disabled]{
        background:$accent !important;
        border-color:$accent !important;
        pointer-events:none !important;
        color:darken($accent,15%);
        animation:none !important;
    }
    
    &.prev,
    &.next {
        text-align:left;

        em {
            display:block;
            font-size:0.8em;
            margin-top:5px;
            font-weight: 500;
            font-style: normal;
            line-height: 1em;
        }

        &[class*="icon-"]:before {
            background:$secondary;
            line-height: calc(1em + 34px + 0.8em )
        }

        &.next-results {
             > span {
                padding:24px 18px;
             }
        }
    }

    &.prev {
        color: $secondary;
        border:2px solid $secondary;

        > span {
            background:transparent;
            padding:13px 18px;
        }

        &[class*="icon-"]{
            padding-right:0;
            padding-left:calc(1em + 30px);

            &:before{
                right:auto;
                left:0;
                background:transparent;
                line-height: calc(1em + 30px + 0.8em);
            }
        }
        
        &:focus,
        &:hover {
            > span {
                background:$secondary;
                color:#fff;
            }
            &[class*="icon-"]:before {
                background:$secondary;
                color:#fff;
            }
        }
    }
}
ul[data-loading=true]{
    position: relative;

    &:before{
        @include loader02($color:$primary);
        content:"";
        display:block;
        position: absolute;
        top:0;
        left:calc(50% - 28px);
        z-index:999;
    }
}

@keyframes secondary-pulse {
    0%      { border-color:$secondary; background:$secondary; }
    50%     { border-color:darken($secondary,25%); background:darken($secondary,25%); }
    100%    { border-color:$secondary; background:$secondary; }
}

.btn-inline{
    color:$secondary;
    background:transparent;
    text-transform: uppercase;
    font-size:em(27.17,$fs);
    border:none;
    outline:none;
    padding:0;
    font-weight:bold;
    position: relative;
    transition:all 300ms ease-in-out;
    
    &:focus,
    &:hover{
        color:darken($secondary,15%);
    }

    &[class*="icon-"]{
        padding-left:50px;

        &:before{
            position: absolute;
            top:50%;
            left:0;
            font-size:1.5em;
            transform:translateY(-50%);
        }
    }

    &.primary{
        color:$primary !important;
        
        &:focus,
        &:hover{
            color:lighten($primary,15%) !important;
        }
    }

    &.lower{
        text-transform: none;
    }
}

.ReactModalPortal{
    position: relative;
    z-index:9999;
}

.ReactModal__Overlay{
    transition:all 300ms ease-in-out;
    background:rgba(0,0,0,.75) !important;
}

.ReactModal__Overlay--before-close{
    opacity:0;
    transition:all 300ms ease-in-out;
}

.ReactModal__Content--before-close{
    transform:translateX(-50%) translateY(-55%) scale(.95) !important;
    opacity:0;
    transition:all 300ms ease-in-out;
}

.ReactModal__Content{
    transition:opacity 300ms ease-in-out,transform 300ms ease-in-out;
    opacity:0;
    padding:30px;
    transform:translateX(-50%) translateY(-55%) scale(.95);
}

.ReactModal__Content--after-open{
    opacity:1;
    transform:translateX(-50%) translateY(-50%) scale(1);
}

.btn-modal-close{
    position: absolute;
    top:15px;
    right:15px;
    border:none;
    outline:none;
    padding:0;
    width:40px;
    background:none;
    height:40px;
    text-indent: -99999px;

    &:before{
        content:"\e807";
        display:block;
        text-indent:0;
        font-family: $icon;
        font-size:em(60,$fs);
        position: absolute;
        top:50%;
        left:50%;
        color:$primary;
        transform:translateX(-50%) translateY(-50%);
    }
}

.modal-actions{
    list-style:none;
    margin:0;
    padding:0;

    > li{
        + li{
            margin:10px 0 0;
        }
    }
}

.btn-help{
    display:inline-block;

    > button{
        display:inline-block;
        width:em(27,$fs);
        height:em(27,$fs);
        padding:0;
        margin:0 0 0 em(15,32.79);
        background:$secondary;
        color:$white !important;
        border-radius:50%;
        text-align: center;
        line-height:1em;
        font-weight: bold;
        font-size:20px;
        border:none;
        outline:none;
        transition:all 300ms ease-in-out;
        
        &:focus,
        &:hover{
            background:darken($secondary,15%);
        }
    }
}

.capitalize{
    text-transform: capitalize;
}