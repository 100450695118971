.screen-wrap {
	.headline {
		display:flex;
		// align-items: flex-start;
		justify-content: space-between;
		flex-direction: column;
	}
}

.table-wrap {
	overflow-x: scroll;
}

.btn-container {
	position: relative;
	display: flex;
	align-items: center;
	margin-top: 15px;
	flex-wrap: wrap;
	justify-content: center;
	.print-btn,
	.restart-btn{	
		background:transparent;
		display:inline-block;
		color:$secondary;
		outline:none;
		line-height:1;
		padding:0;
		min-width:250px;
		text-decoration: none;
		font-size:1.4em;
		font-weight:700;
		position: relative;
		border: 0 none;
		white-space: nowrap;

		span,
		svg {
			display:inline-block;
			vertical-align: middle;
		}

		svg {
			margin-right: 5px;
			path {
				fill: $secondary;
				transition:all 300ms;
			}
		}

		&::before {
			font-size:1.5em;
			display:inline-block;
			vertical-align: middle;
			transition:all 300ms;
		}

		&:hover,
		&:focus {
			&::before {
				color:$primary;
			}
			svg {
				path {
					fill: $primary;
				}
			}
		}
		
	}
}

