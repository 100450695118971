.status-bar {
	background: $grey2;
	padding:24px 0;

	> .container {
		max-width: $hwidth;
		position:relative;
		
		> .container {
			display: flex;
			justify-content: space-between;
			position:relative;
			z-index:2;
		}
	}

	.progress {
		position:absolute;
		top:20px;
		left:50%;
		background: $secondary;
		height:4px;		
		max-width: $width - 80px;
		width: calc( 100% - 120px );
		transform: translateX( -50% );
		z-index:1;

		&::before {
			content:'';
			position: absolute;
			top:0;
			left:0;
			width:0%;
			height:100%;
			transition: all 300ms;
			background: $primary;
		}
	}

	&.step-1 {
		.progress::before {
			width:16.666667%;
		}
	}

	&.step-2 {
		.progress::before {
			width:50%;
		}
	}

	&.step-3 {
		.progress::before {
			width:83.3333333%;
		}
	}

	&.step-4 {
		.progress::before {
			width:100%;
		}
	}

	a {
		position:relative;
		// padding-bottom: 1.75em;
		color:$secondary;
		text-decoration: none;
		display:block;
		
		&:focus,
		&:hover {
			color:$primary;
		}

		&:focus {
			span {
				box-shadow:0 0 20px $primary;
			}
		}

		span {
			width:40px;
			height:40px;
			line-height: 35px;
			display:block;
			border-radius:50%;
			text-align:center;
			border:3px solid;
			font-size:20px;
			font-weight: 700;
			background: $grey2;
			transition: all 300ms;
		}
		strong {
			display:none;
			font-weight:400;
			transform: translateX(-50%);
			position:absolute;
			bottom:0;
			left:50%;
			white-space: nowrap;
			color:$primary;
			font-size:0.9em;
		}

		&.current {
			span {
				background: $accent;
				color: #fff;
				border-color: $primary;
			}
		}

		&.past {
			span {
				background: $primary;
				color: #fff;
				border-color: $primary;
			}
		}
	}
}