.screen-login {
	background: url(../../images/bg.jpg) 50% 50%;
	background-size: cover;
	background-color:transparent !important;

	header {
		background:transparent;
		color:#fff;
	}

	footer {
		background:transparent;
	}

	main .container {
		max-width: 790px;
		text-align:center;
	}
	h2 {
		color: #fff;

		+ p {
			color: #fff;
		}	
	}

	.terms-box-container {
		position:relative;

		.sticky-scroll {
			position:absolute;
			bottom:0;
			left:0;
			width:100%;
			pointer-events: none;
			background:$secondary;
			padding:10px;
			opacity: 1;
			transition: opacity 300ms;
			color:#fff;

			&::before {
				margin-right:6px;
			}

			&.hidden {
				opacity:0;
			}
		}
	}

	.terms-box {
		width: 100%;
		height: 320px;
		overflow: scroll;
		-webkit-overflow-scrolling: touch;
		background: #fff;
		padding: 20px;
		margin-bottom: 20px;
		text-align: left;
		border:1px solid transparent;

		&:focus {
			border:1px solid $secondary;
			box-shadow: 0 0 5px $secondary;
		}

		h6 {
			text-align: center;
		}

		ol li + li {
			margin-top:8px;
		}

		p:last-child {
			margin:0;
		}
	}
}