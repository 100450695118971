@include mqmin( $phone ) {
	.btn-container {
		flex-wrap: wrap;
	}
	.table-wrap {
		overflow-x: hidden;
	}
}

@include mqmin( $ipad ) {

	header {
		.links {
			white-space: nowrap;

			> * {
				display:inline-block;
			}
		}
		.font-sizer {
			padding-right:20px;
			margin-bottom:0;
		}
	}

	footer {
		.container {
			display:flex;
			text-align: left;
			line-height:40px;
		}
	}

	.sections {
		&.two-column {
			display:flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.section {
				width:calc(50% - 20px);

				~ .section {
					border-top:0 none;
					padding-top:0;
					margin-top:0;
					// margin-bottom: 25px;
					&.padding-top {
						margin-top: 25px;
					}
					&.full-width {
						width: 100%;
						.field {
							width:calc(50% - 20px);
						}
					}
					.field.review {
						border-top:0 none;

						+ .field {
							border-top:1px solid rgba( $primary, 0.1 );
						}
					}
				}
			}
		}
	}
	.field.years-of-service-chart {
		.columns-2 {
			display: flex;
			justify-content: space-between;
			table {
				width: calc(50% - 20px);
			}
		}
	}
	.field.memberResultTabs {
		.tabs {
			display:flex;

			> a {
				width: calc(20% - 12px);
				margin-bottom:0;
			}
			.or {
				width:30px;
				margin-bottom:0;
			}

			&.tabs-4 > a {
				width: calc(25% - 12px);
			}

			&.tabs-3 > a {
				width: calc(33.333333% - 12px);
			}

			&.tabs-2 > a {
				width: calc(50% - 12px);
			}
		}
	}
	.field.memberResultsTable {
		.caption h6 {
			margin-bottom: 10px;
			font-size:1.2em;
		}
		.swipe {
			display:none;
		}
	}
	.status-bar {
		a {
			padding-bottom: 1.75em;

			strong {
				display:block;
			}
		}
	}
	.screen-wrap {
		.headline {
			flex-direction: row;
		}
	}
	.btn-container {
		flex-wrap: wrap;
		justify-content: center;
	}
}

@include mqmin( $desktop ) {

}

@include mqmin( $large ) {

}