/*!
Theme Name: TRI-M
Author: Weidenhammer Creative
Author URI: http://weidenhammercreative.com
*/

// Google Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700');

// Bourbon

// Reset
@import "base/reset";

// Variables must be loaded before the grid to override grid settings
@import "mixins/variables";

// Mixins
@import "mixins/custom-mixins";
@import "partials/spinners";

// General Styling
@import "partials/fonts";
@import "partials/globals";
@import "partials/header";
@import "partials/forms";
@import "partials/footer";
@import "partials/statusbar";
@import "partials/login";
@import "partials/fields";
@import "partials/screens";
@import "partials/print";

@import "partials/browser-fixes";

// Page / Post / Ect - Specific


// Responsive
@import "partials/responsive";