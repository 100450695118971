header {
	background:#fff;

	.cookie-notice {
		background:linear-gradient(30deg,$secondary,darken($secondary,20));
		padding:0;
		
		.container {
			display:flex;
			align-items:center;

			a {
				color:$primary;
			}
		}

		.message {			
			padding:10px 10px 10px 0;
			border-right:1px solid $primary;
		}

		.icon-cancel {
			cursor: pointer;
			min-width:35px;
			font-size:1.5em;
			text-align:right;
		}
	}
	
	> .container {
		height:120px;
		display:flex;
		justify-content: space-between;
		align-items: center;
		max-width: $hwidth;
	}

	.links {
		margin-left:15px;
		// white-space: nowrap;

		> * {
			display:block;
		}
	}

	.font-sizer {
		// display:inline-block;
		// padding-right:20px;
		text-align:center;
		margin-bottom:5px;

		a {
			padding:0 2px;
			border-bottom:1px solid transparent;

			&:hover,
			&:focus {
				border-bottom:1px solid;
			}

			&.current {
				font-weight:700;
			}
			&.small {
				font-size:14px;
			}
			&.medium {
				font-size:20px;
			}
			&.large {
				font-size:24px;
			}
		}
	}

	.logo {
		svg {
			width:100%;
			max-width:300px;

			path {
				transition: all 500ms;
			}
		}
		span {
			display:none;
		}
	}
}