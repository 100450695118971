footer {
	position: absolute;
	left:0;
	bottom:0;
	width:100%;
	// height:40px;
	background: $primary;
	color:#fff;

	.container {
		// display:flex;
		justify-content: space-between;
		align-items:middle;
		// height:40px;
		padding-top:9px;
		padding-bottom:9px;
		text-align: center;
	}

	.right {
		a {
			padding:0 8px;

			~ a {
				border-left:1px solid #fff;
			}
		}
	}
}