@font-face {
  font-family: 'kornferry';
  src: url('../../font/kornferry.eot?27166774');
  src: url('../../font/kornferry.eot?27166774#iefix') format('embedded-opentype'),
       url('../../font/kornferry.woff2?27166774') format('woff2'),
       url('../../font/kornferry.woff?27166774') format('woff'),
       url('../../font/kornferry.ttf?27166774') format('truetype'),
       url('../../font/kornferry.svg?27166774#kornferry') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'kornferry';
    src: url('../font/kornferry.svg?27166774#kornferry') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "kornferry";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-ok:before { content: '\e800'; } /* '' */
.icon-cancel:before { content: '\e801'; } /* '' */
.icon-iconmonstr-printer-3:before { content: '\e802'; } /* '' */
.icon-iconmonstr-refresh-2:before { content: '\e803'; } /* '' */
.icon-down-open-1:before { content: '\e805'; } /* '' */
.icon-left-open-1:before { content: '\e806'; } /* '' */
.icon-right-open-1:before { content: '\e807'; } /* '' */
.icon-up-open-1:before { content: '\e808'; } /* '' */