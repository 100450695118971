

@media print {

	// body,
	// .disclaimer {
	// 	color:#000;
	// }

	.no-print {
		visibility: hidden;
	}

	.table-wrap {
		overflow-x: hidden;
	}

	.font-size-small,
	.font-size-medium,
	.font-size-large {
		font-size:10px;
	}

	#root {
		overflow: visible;
	}
	
	header .links,
	.print-btn,
	.status-bar,
	.field.memberResultTabs {
		display:none;
	}

	.field.memberResultsTable {

		~ .field.memberResultsTable {
			page-break-before: always;
		}

		.swipe {
			display:none;
		}
	}

	.section.print-page-break {
		page-break-before: always;
	}

}